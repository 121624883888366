<template>
  <div class="screen-action">
    <skeleton-input v-if="isLoading" width="228px" />
    <ui-button
      v-else
      :button="button"
      :variant="productLine"
      :icon="icon"
      :label="label"
      @click="onClick"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import SkeletonInput from '@/components/Skeleton/Input.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'ScreenAction',
  components: {
    SkeletonInput,
    UiButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: String,
      required: false,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    productLine() {
      return this.$route.meta.productLine || 'data'
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $gutter-mobile;
  margin-bottom: $gutter-mobile / 2;
  width: 100%;

  @media (min-width: $screen-sm) {
    margin-top: $gutter-tablet;
    margin-bottom: $gutter-tablet / 2;
  }

  @media (min-width: $screen-xl) {
    margin-top: $gutter-desktop;
    margin-bottom: $gutter-desktop / 2;
  }
}
</style>
