<template>
  <modal
    :title="$t('modal.confirm.title')"
    name="modal-confirm"
    :has-apply="true"
    :apply-label="$t('common.button.continue')"
    :is-updating="isUpdating"
    @save="save"
    @cancel="cancel"
    @closed="closed"
  >
    <template v-slot:container>
      {{ $t(`modal.confirm.description.${parameters.mode}`) }}
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
        mode: '',
        element: null,
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    save() {
      this.$emit('save', {
        type: this.parameters.type,
        mode: this.parameters.mode,
        element: this.parameters.element,
      })
    },
    cancel() {
      this.$emit('cancel', {
        type: this.parameters.type,
        mode: this.parameters.mode,
        element: this.parameters.element,
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>
