<template>
  <div class="ui-clipper-gmb">
    <div
      class="ui-clipper-gmb__container"
      v-if="picture || image.base64"
      @drop.prevent="cropImg($event.dataTransfer.files[0])"
      @dragover.prevent
    >
      <img :src="picture || image.base64" class="ui-clipper-gmb__container__img" alt="preview" />
      <div class="ui-clipper-gmb__container__actions" v-if="type !== 'locationPictures'">
        <ui-button
          class="ui-clipper-gmb__container__actions__edit"
          button="cta"
          variant="data"
          icon="add_photo"
          :icon-only="true"
          :label="$t('common.button.updateImg')"
          v-tooltip="{
            placement: 'top',
            trigger: 'hover',
            content: $t('common.button.updateImg'),
            offset: 3,
          }"
          @click.stop="$refs.mediaImg.click()"
        />
        <ui-button
          class="ui-clipper-gmb__container__actions__delete"
          button="cta"
          variant="error"
          icon="delete"
          :icon-only="true"
          :label="$t('common.button.deleteImg')"
          v-tooltip="{
            placement: 'top',
            trigger: 'hover',
            content: $t('common.button.deleteImg'),
            offset: 3,
          }"
          @click.stop="deleteImg"
        />
      </div>
    </div>
    <div
      class="ui-clipper-gmb__placeholder"
      v-else
      @click.stop="$refs.mediaImg.click()"
      @drop.prevent="cropImg($event.dataTransfer.files[0])"
      @dragover.prevent
    >
      <ui-button
        class="ui-clipper-gmb__placeholder__add"
        button="primary"
        variant="data"
        icon="add_photo"
        :icon-only="true"
        :label="$t('common.button.addImg')"
        v-tooltip="{
          placement: 'top',
          trigger: 'hover',
          content: $t('common.button.addImg'),
          offset: 3,
        }"
        @click.stop="$refs.mediaImg.click()"
      />
      <div class="ui-clipper-gmb__placeholder__caption">
        {{ $t('modal.clipper.noPicture') }}
      </div>
    </div>
    <input
      type="file"
      accept="image/*"
      ref="mediaImg"
      class="ui-clipper-gmb__file"
      @change="cropImg($event.target.files[0])"
    />
    <div class="ui-clipper-gmb__recommended-size">
      {{ recommendedSize }}
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import { notification } from '@/utils/notification.util'

export default {
  name: 'UiClipperGmb',
  components: {
    UiButton,
  },
  props: {
    picture: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    category: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      image: {
        preview: null,
        file: null,
        type: null,
        base64: null,
      },
      notificationText: null,
    }
  },
  computed: {
    recommendedSize() {
      if (this.category === 'PROFILE') {
        return this.$t(`modal.clipperGmb.recommended.profile`)
      } else if (this.category === 'COVER') {
        return this.$t(`modal.clipperGmb.recommended.cover`)
      } else {
        return this.$t(`modal.clipperGmb.recommended.default`)
      }
    },
  },
  methods: {
    cropImg(file) {
      this.image.file = file
      const image = new Image()
      const imageReader = new FileReader()

      imageReader.addEventListener(
        'load',
        () => {
          this.image.preview = imageReader.result
          image.src = this.image.preview
        },
        false
      )
      image.addEventListener(
        'load',
        () => {
          if (this.checkRules(image)) {
            this.image.base64 = this.image.preview
            this.$emit('clip', this.image.preview)
          } else {
            notification({
              text: this.$t(this.notificationText),
              type: 'warning',
            })
          }
        },
        false
      )
      if (this.image.file) {
        this.image.type = this.image.file.type
        imageReader.readAsDataURL(this.image.file)
      }
      this.$refs.mediaImg.value = ''
    },
    deleteImg() {
      this.image.base64 = null
      this.$emit('delete')
    },
    calculateGCD(a, b) {
      return b == 0 ? a : this.calculateGCD(b, a % b)
    },
    checkRules(image) {
      const gcd = this.calculateGCD(image.width, image.height)

      if (this.image.type !== 'image/jpg' && this.image.type !== 'image/jpeg' && this.image.type !== 'image/png') {
        this.notificationText = 'modal.clipperGmb.notification.type'
        return false
      }
      if (this.image.file.size < 10000 || this.image.file.size > 5000000) {
        this.notificationText = 'modal.clipperGmb.notification.size'
        return false
      }
      if (
        this.category === 'PROFILE' &&
        (image.width < 120 || image.height < 120 || image.width > 5200 || image.height > 5300)
      ) {
        this.notificationText = 'modal.clipperGmb.notification.profile'
        return false
      }
      if (
        this.category === 'COVER' &&
        (image.width < 480 || image.height < 270 || image.width > 2120 || image.height > 1192)
      ) {
        this.notificationText = 'modal.clipperGmb.notification.cover'
        return false
      }
      if (this.category === 'COVER' && (image.width / gcd !== 16 || image.height / gcd !== 9)) {
        this.notificationText = 'modal.clipperGmb.notification.coverAspectRatio'
        return false
      } else if (image.width < 497 || image.height > 2048 || image.width > 2048) {
        this.notificationText = 'modal.clipperGmb.notification.default'
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-clipper-gmb {
  &__container {
    display: flex;
    justify-content: center;
    border-radius: $radius-default;
    background-color: var(--bg-color-hue);
    overflow: hidden;

    &__img {
      display: flex;
      border-radius: $radius-default;
      max-width: 100%;
    }

    &__actions {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding: 6px;

      &__delete {
        margin-left: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-left: $gutter-tablet;
        }
      }
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $radius-input;
    background-color: var(--bg-color-hue);
    cursor: pointer;
    height: 150px;

    @media (min-width: $screen-sm) {
      height: 200px;
    }

    &__add {
      margin-bottom: 4px;
    }

    &__caption {
      color: var(--input-placeholder-color);
    }
  }

  &__file {
    display: none;
  }

  &__recommended-size {
    margin-top: 10px;
    text-align: center;
    color: var(--input-placeholder-color);
  }
}
</style>
