<template>
  <vue-good-table
    style-class="vgt-table"
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-cell-click="onCellClick"
    @on-selected-rows-change="onSelectedRowsChanged"
    @on-select-all="onSelectedRowsChanged"
    :totalRows="totalRows"
    :rows="rows"
    :columns="columns"
    :pagination-options="paginationOptions"
    :isLoading="isLoading"
    :select-options="selectOptions"
  >
    <template slot="loadingContent">
      <ui-table-loader :is-loading="isLoading" />
    </template>
    <template slot="table-row" slot-scope="{ row, column, formattedRow }">
      <slot name="data-table-row" :row="row" :column="column" :formatted-row="formattedRow" />
    </template>
  </vue-good-table>
</template>

<script>
import UiTableLoader from '@/components/UI/TableLoader.vue'

export default {
  name: 'UiDataTable',
  components: {
    UiTableLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
    },
    isSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    withPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableParams: {
        sort: {
          field: '',
          type: '',
        },
      },
      paginationOptions: this.withPagination
        ? {
            enabled: true,
            perPage: 100,
            perPageDropdown: [25, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: this.pagination.page,
            prevLabel: this.$t('ui.dataTable.pagination.prev'),
            nextLabel: this.$t('ui.dataTable.pagination.next'),
            rowsPerPageLabel: this.$t('ui.dataTable.pagination.rowsPerPage'),
            ofLabel: this.$t('ui.dataTable.pagination.of'),
            pageLabel: this.$t('ui.dataTable.pagination.page'),
          }
        : { enabled: false },
    }
  },
  computed: {
    selectOptions() {
      return {
        enabled: this.isSelectable,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true,
        selectAllByGroup: true,
      }
    },
  },
  methods: {
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, size: params.currentPerPage })
      this.loadItems()
    },
    onSortChange([params]) {
      this.updateParams({
        page: 1,
        sort: {
          type: params.type.toUpperCase(),
          field: params.field,
        },
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onCellClick(event) {
      this.$emit('onCellClick', event)
    },
    loadItems() {
      this.$emit('loadItems', this.tableParams)
    },
    onSelectedRowsChanged(params) {
      this.$emit('onSelectedRowsChanged', params.selectedRows)
    },
  },
}
</script>
