<template>
  <modal :title="title" name="modal-address" :has-apply="true" :is-updating="isPending" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-input
        class="modal-address__input modal-address__input--successive"
        v-model="modelData.street1"
        :label="$t('modal.address.label.street1')"
        id="modal-address-street1"
        :error="$v.modelData.street1.$error"
        @enter="save"
      >
        <template v-if="$v.modelData.street1.$error && !$v.modelData.street1.required">
          {{ $t('errors.required') }}
        </template>
      </ui-input>
      <ui-input
        class="modal-address__input"
        v-model="modelData.street2"
        :label="$t('modal.address.label.street2')"
        id="modal-address-street2"
        @enter="save"
        design="minimalist"
      >
      </ui-input>
      <ui-input
        class="modal-address__input"
        v-model="modelData.postalCode"
        :label="$t('modal.address.label.postalCode')"
        id="modal-address-postalCode"
        @enter="save"
      >
      </ui-input>
      <ui-input
        class="modal-address__input"
        v-model="modelData.city"
        :label="$t('modal.address.label.city')"
        id="modal-address-city"
        :error="$v.modelData.city.$error"
        @enter="save"
      >
        <template v-if="$v.modelData.city.$error && !$v.modelData.city.required">
          {{ $t('errors.required') }}
        </template>
      </ui-input>
      <ui-dropdown
        class="modal-address__input"
        id="modal-address-country-code"
        :value="modelCountryCode"
        :options="countries"
        label="name"
        track-by="countryCode"
        :placeholder="$t('modal.address.label.countryCode.placeholder')"
        :dropdown-label="$t('modal.address.label.countryCode.label')"
        :show-label="true"
        :error="$v.modelData.countryCode.$error"
        open-direction="above"
        @input="setCountryCode"
      >
        <template v-slot:helper v-if="$v.modelData.countryCode.$error && !$v.modelData.countryCode.required">
          {{ $t('errors.required') }}
        </template>
      </ui-dropdown>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ModalAddress',
  components: {
    UiInput,
    UiDropdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        street1: '',
        street2: '',
        postalCode: '',
        city: '',
        countryCode: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      isGeocoding: false,
      countryCodeChanged: false,
    }
  },
  mounted() {
    this.modelData = this.data
  },
  computed: {
    ...mapState({
      countries: state => state.user.countries,
    }),
    isPending() {
      return this.isUpdating || this.isGeocoding
    },
    modelCountryCode() {
      return this.countries.find(country => country.countryCode === this.modelData.countryCode)
    },
  },
  methods: {
    ...mapActions({
      geocodeAddress: 'tool/geocodeAddress',
    }),
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isGeocoding = true
        let fullAddress = this.modelData.street1
        if (this.modelData.postalCode) {
          fullAddress += `,${this.modelData.postalCode}`
        }
        if (this.modelData.city) {
          fullAddress += `,${this.modelData.city}`
        }
        if (this.modelData.countryCode) {
          fullAddress += `,${this.modelData.countryCode}`
        }
        if (this.modelData.countryCode !== this.data.countryCode) {
          this.countryCodeChanged = true
        }
        this.geocodeAddress(fullAddress)
          .then(coordinates => {
            this.modelData = {
              ...this.modelData,
              latitude: coordinates.lat,
              longitude: coordinates.lng,
            }
            this.emitSave()
          })
          .catch(() => this.emitSave())
      }
    },
    closed() {
      this.$emit('closed')
    },
    emitSave() {
      this.isGeocoding = false
      this.$emit('save', {
        objKey: { ...this.modelData, countryCodeChanged: this.countryCodeChanged },
      })
      this.$v.$reset()
    },
    setCountryCode(payload) {
      this.modelData = {
        ...this.modelData,
        countryCode: payload.countryCode,
      }
    },
  },
  validations() {
    return {
      modelData: {
        street1: {
          required,
        },
        city: {
          required,
        },
        countryCode: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-address {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
