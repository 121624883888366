<template>
  <screen-grid :with-margin="true">
    <screen-card :no-padding="true" :overflow-hidden="true">
      <template v-slot:body>
        <div class="screen-tabs" ref="fixedElement" :style="style">
          <div
            class="screen-tabs__wrapper"
            :class="{
              'screen-tabs__wrapper--fixed': elementFixed,
            }"
          >
            <div
              class="screen-tabs__wrapper__container"
              :class="{
                'screen-tabs__wrapper__container--fixed-container': elementFixed,
              }"
            >
              <div
                class="screen-tabs__wrapper__container__tab"
                :class="{
                  'screen-tabs__wrapper__container__tab--selected': currentTab === tab.key && !isLoading,
                  'screen-tabs__wrapper__container__tab--disabled': tab.disabled && !isLoading,
                }"
                v-for="(tab, idx) in filteredTabs"
                :key="`tab-${idx}`"
                @click="!tab.disabled && !isLoading ? setCurrentTab(tab.key) : false"
              >
                <skeleton-line v-if="isLoading" height="18px" width="120px" />
                <template v-else>
                  {{ tab.label }}

                  <div
                    v-if="tabHasStatusDisplay(tab)"
                    :class="
                      tab.status.active
                        ? 'screen-tabs__wrapper__container__tab__dot--active'
                        : 'screen-tabs__wrapper__container__tab__dot--inactive'
                    "
                    class="screen-tabs__wrapper__container__tab__dot"
                  ></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { productColor } from '@/config/productColor.config'

export default {
  name: 'ScreenTabs',
  components: {
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    elementFixed: {
      type: Boolean,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    this.$emit('mounted', this.$refs.fixedElement)
  },
  computed: {
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
    filteredTabs() {
      return this.tabs.filter(tab => tab.key && (tab.access ? tab.access === this.currentUser.type : true))
    },
  },
  methods: {
    tabHasStatusDisplay(tab) {
      return tab?.status?.display === true
    },
    setCurrentTab(tab) {
      this.$emit('click', tab)
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-tabs {
  height: 51px;

  @media (min-width: $screen-md) {
    height: 54px;
  }

  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    &--fixed {
      position: fixed;
      top: $topbar-height;
      right: 0;
      left: 0;
      z-index: 2;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--bg-color);
    }

    &__container {
      @include container;

      padding: 0;

      @media (min-width: $screen-sm) {
        padding: 0;
      }

      &--fixed-container {
        @media (min-width: $screen-sm) {
          padding-right: $gutter-tablet;
          padding-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          padding-right: $gutter-desktop;
          padding-left: $gutter-desktop;
        }
      }

      &__tab {
        display: inline-flex;
        position: relative;
        cursor: pointer;
        padding: $gutter-mobile;
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: 600;

        @media (min-width: $screen-sm) {
          padding: $gutter-mobile $gutter-tablet;
        }

        @media (min-width: $screen-md) {
          font-size: $font-size-default;
        }

        &__dot {
          position: absolute;
          top: calc(#{$gutter-mobile} / 1.5);
          right: calc(#{$gutter-tablet} / 1.7);
          border-radius: 50%;
          width: 8px;
          height: 8px;
          &--active {
            background-color: map-get($generic-color-variants, 'success');
          }
          &--inactive {
            background-color: map-get($generic-color-variants, 'error');
          }
        }

        &:hover {
          background-color: var(--bg-color-hover);
        }

        &--selected {
          border-bottom: 4px solid;
          color: var(--product-color);
        }

        &--disabled {
          opacity: 0.4;
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
