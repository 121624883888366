<template>
  <screen-grid class="location-gmb-general">
    <screen-card align="center" ratio="1-3">
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.general.section.verifiedLocation.label')"
          align="center"
          :has-data="true"
          :class="{
            'location-gmb-general__status--active': verifiedLocation,
            'location-gmb-general__status--inactive': !verifiedLocation,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div class="location-gmb-general__status__icon backoffice-icons" v-else>check</div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-gmb-general__status__state" v-else>
              <template v-if="verifiedLocation">
                {{ $t('locationsGmb.id.general.section.verifiedLocation.status.active') }}
              </template>
              <template v-else-if="!verifiedLocation">
                {{ $t('locationsGmb.id.general.section.verifiedLocation.status.inactive') }}
              </template>
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card align="center" ratio="1-3">
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.general.section.activeLocation.label')"
          align="center"
          :class="{
            'location-gmb-general__status--active': activeLocation,
            'location-gmb-general__status--inactive': !activeLocation,
          }"
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div class="location-gmb-general__status__icon backoffice-icons" v-else>gmb</div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-gmb-general__status__state" v-else>
              <template v-if="activeLocation">
                {{ $t('locationsGmb.id.general.section.activeLocation.status.active') }}
              </template>
              <template v-else-if="!activeLocation">
                {{ $t('locationsGmb.id.general.section.activeLocation.status.inactive') }}
              </template>
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card align="center" ratio="1-3">
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.general.section.openStatus.label')"
          align="center"
          :class="`location-gmb-general__status--${openInfoStatus}`"
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-gmb-general__status__icon backoffice-icons">hours</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-gmb-general__status__state" v-else>
              {{ $t(`locationsGmb.id.general.section.openStatus.status.${openInfoStatus}`) }}
            </div>
          </template>
          <template v-slot:right>
            <skeleton-icon v-if="isLoading" height="44px" width="44px" margin-left="16px" />
            <v-popover placement="bottom-end" :autoHide="true" popoverInnerClass="popover-inner no-overflow" v-else>
              <ui-button
                class="location-gmb-general__status__button"
                button="primary"
                variant="data"
                icon="edit"
                :icon-only="true"
                :label="$t('common.button.edit')"
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover',
                  content: $t('common.button.edit'),
                  offset: 3,
                }"
              />
              <template slot="popover">
                <div class="location-gmb-general__status__popover">
                  <div
                    class="location-gmb-general__status__popover__choice"
                    v-for="status in openStatuses"
                    :key="status.value"
                    @click="updateOpenStatus(status.value)"
                  >
                    <div class="location-gmb-general__status__popover__choice__section">
                      <span
                        class="location-gmb-general__status__popover__choice__section__icon backoffice-icons"
                        v-if="openInfoStatus === status.value"
                      >
                        check
                      </span>
                      <span class="location-gmb-general__status__popover__choice__section__label">
                        {{ status.label }}
                      </span>
                    </div>
                    <div class="location-gmb-general__status__popover__choice__section">
                      <ui-loader
                        class="location-gmb-general__status__popover__choice__section__loader"
                        v-if="isUpdating && openInfoStatus === status.value"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </v-popover>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.general.section.generalData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.locationName')"
          icon="article"
          :has-data="typeof currentLocation.locationName === 'string'"
          :data="currentLocation.locationName"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('locationName', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.primaryCategory')"
          icon="attribute"
          :has-data="hasPrimaryCategory"
          :data="currentLocation.primaryCategory ? currentLocation.primaryCategory.displayName : ''"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('primaryCategory', 'text', 'choice', false, null, primaryCategories, {
              label: 'displayName',
              trackBy: 'categoryId',
            })
          "
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.storeCode')"
          icon="business"
          :has-data="typeof currentLocation.storeCode === 'string'"
          :data="currentLocation.storeCode"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('storeCode', 'text', 'input')"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.address')"
          icon="pin"
          :has-data="typeof currentLocation.address === 'object' && addressLines !== ''"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editAddress"
        >
          <template v-slot:data>
            <div v-if="currentLocation.address.addressLines && currentLocation.address.addressLines.length > 0">
              {{ currentLocation.address.addressLines[0] }}
            </div>
            <div
              v-if="
                currentLocation.address.addressLines &&
                currentLocation.address.addressLines.length > 1 &&
                currentLocation.address.addressLines[1] !== ''
              "
            >
              {{ currentLocation.address.addressLines[1] }}
            </div>
            <div>
              <span v-if="currentLocation.address.postalCode">
                {{ currentLocation.address.postalCode }}
              </span>
              {{ currentLocation.address.locality }}
            </div>
            <div>
              <span v-if="currentLocation.address.regionCode">
                {{ mapCountry(currentLocation.address.regionCode) }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.additionalCategories')"
          icon="attribute"
          :has-data="
            Array.isArray(currentLocation.additionalCategories) && currentLocation.additionalCategories.length > 0
          "
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('additionalCategories', 'text', 'list', false, null, additionalCategories, {
              label: 'displayName',
              trackBy: 'categoryId',
            })
          "
        >
          <template v-slot:data>
            <div v-for="(category, idx) in currentLocation.additionalCategories" :key="`category-${idx}`">
              {{ category.displayName }}
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locationsGmb.id.extras.section.locationIds.label.placeId')"
          icon="attribut"
          :has-data="!!hasPlaceId && typeof currentLocation.locationKey.placeId === 'string'"
          :data="hasPlaceId ? currentLocation.locationKey.placeId : ''"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.primaryPhone')"
          icon="phone"
          :has-data="typeof currentLocation.primaryPhone === 'string'"
          :data="currentLocation.primaryPhone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('primaryPhone', 'phone', 'input', true)"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.additionalPhones')"
          icon="phone"
          :has-data="Array.isArray(currentLocation.additionalPhones) && currentLocation.additionalPhones.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editList('additionalPhones', 'text', 'list')"
        >
          <template v-slot:data>
            <div v-for="(phone, idx) in currentLocation.additionalPhones" :key="`phone-${idx}`">
              {{ phone }}
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locationsGmb.id.extras.section.extraData.label.languageCode')"
          icon="language"
          :has-data="typeof currentLocation.languageCode === 'string'"
          :data="currentLocation.languageCode"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.generalData.label.websiteUrl')"
          icon="link"
          :has-data="typeof currentLocation.websiteUrl === 'string'"
          :data="currentLocation.websiteUrl"
          ratio="1-3"
          :is-loading="isLoading"
          :check-link="true"
          @click="editSimpleField('websiteUrl', 'url', 'input', true)"
        />
        <screen-block
          class="location-gmb-general__coordinates"
          :title="$t('locationsGmb.id.general.section.generalData.label.gps')"
          icon="geoloc"
          :has-data="
            currentLocation.latlng !== undefined &&
            typeof currentLocation.latlng.latitude === 'number' &&
            typeof currentLocation.latlng.longitude === 'number'
          "
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
          @click="editCoordinates"
        >
          <template v-slot:data>
            <div class="location-gmb-general__coordinates__section">
              <div class="location-gmb-general__coordinates__section__label">
                {{ $t('common.label.latitude') }}
              </div>
              <div class="location-gmb-general__coordinates__section__value">
                {{ currentLocation.latlng.latitude }}
              </div>
            </div>
            <div class="location-gmb-general__coordinates__section">
              <div class="location-gmb-general__coordinates__section__label">
                {{ $t('common.label.longitude') }}
              </div>
              <div class="location-gmb-general__coordinates__section__value">
                {{ currentLocation.latlng.longitude }}
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locationsGmb.id.general.section.advancedData.label.description')"
          icon="text"
          :has-data="!!hasDescription && typeof currentLocation.profile.description === 'string'"
          :data="hasDescription ? currentLocation.profile.description : ''"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editSimpleField('profile.description', 'text', 'textarea', true)"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.general.section.openingHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-if="currentLocation.regularHours != null"
          class="location-gmb-general__hours"
          :title="
            $tc('locationsGmb.id.general.section.openingHours.label.hourSet', hasOpeningHours, {
              position: currentLocation.regularHours.position,
            })
          "
          icon="hours"
          :has-data="hasOpeningHours"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editOpeningHours(currentLocation.regularHours)"
        >
          <template v-slot:data>
            <div
              class="location-gmb-general__hours__slot"
              v-for="(slots, day, idx) in currentLocation.regularHours.hours"
              :key="`opening-hour-${idx}`"
            >
              <div class="location-gmb-general__hours__slot__day">
                {{ $t(`common.days.${day}`) }}
              </div>
              <div class="location-gmb-general__hours__slot__time">
                <div v-if="slots.length === 0">
                  {{ $t('common.label.closed') }}
                </div>
                <div
                  v-else-if="
                    slots.length === 1 && slots[0].split('-')[0] === '00:00' && slots[0].split('-')[1] === '00:00'
                  "
                >
                  {{ $t('common.label.h24') }}
                </div>
                <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                  {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
                </div>
              </div>
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.general.section.exceptionalOpeningHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-for="(exceptionalHour, idx) in exceptionnalHours"
          :key="`exceptional-hour-${idx}`"
          title=""
          icon="date"
          :has-data="exceptionnalHours.length > 0"
          class="location-gmb-general__hours"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editExceptionalHours(exceptionalHour)"
        >
          <template v-slot:data>
            <div class="location-gmb-general__hours__slot">
              <div class="location-gmb-general__hours__slot__day">
                <template
                  v-if="
                    exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                    (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                  "
                >
                  {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
                </template>
                <template v-else>
                  {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                  {{ date(exceptionalHour.dateTo) }}
                </template>
              </div>
              <div class="location-gmb-general__hours__slot__time">
                <div v-if="!exceptionalHour.openStatus">
                  {{ $t('common.label.closed') }}
                </div>
                <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                  {{ $t('common.label.open') }}
                </div>
                <template v-else>
                  <div v-if="exceptionalHour.hours1">
                    {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
                  </div>
                  <div v-if="exceptionalHour.hours2">
                    {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          v-if="exceptionnalHours.length === 0"
          :title="$t('locationsGmb.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="date"
          :has-data="exceptionnalHours.length > 0"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locationsGmb.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          @click="editExceptionalHours()"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locationsGmb.id.general.section.advancedData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locationsGmb.id.general.section.advancedData.label.openDate')"
          icon="date"
          :has-data="currentLocation.openInfo !== undefined && typeof currentLocation.openInfo.openingDate === 'string'"
          :data="currentLocation.openInfo ? openingDate(currentLocation.openInfo.openingDate) : ''"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editSimpleField('openInfo.openingDate', 'text', 'datepicker')"
        />
        <screen-block
          :title="$t('locationsGmb.id.general.section.advancedData.label.labels')"
          icon="attribut"
          :has-data="Array.isArray(currentLocation.labels) && currentLocation.labels.length > 0"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editList('labels', 'text', 'list')"
        >
          <template v-slot:data>
            <span v-for="(tag, idx) in currentLocation.labels" :key="`location-label-${idx}`">
              {{ tag }}<template v-if="idx < currentLocation.labels.length - 1">, </template>
            </span>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      v-if="printableAdmins.length"
      ratio="1-1"
      :is-loading="isLoading"
      :title="$t('locationsGmb.id.general.section.permissions.title')"
    >
      <template v-slot:body>
        <div class="location-gmb-general__table">
          <ui-data-table
            :columns="[
              {
                label: $t('gmb.list.id.admin.columns.adminName'),
                field: 'admin',
              },
              {
                label: $t('gmb.list.id.admin.columns.role'),
                field: 'role',
              },
            ]"
            :rows="printableAdmins"
            :total-rows="printableAdmins.length"
            :is-loading="isLoading"
            :with-pagination="false"
          />
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiLoader from '@/components/UI/Loader.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LocationGeneral',
  components: {
    UiButton,
    UiLoader,
    UiDataTable,
    SkeletonLine,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    SkeletonIcon,
    ScreenAction,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openStatuses: [
        {
          label: this.$t('locationsGmb.id.general.section.openStatus.status.OPEN_FOR_BUSINESS_UNSPECIFIED'),
          value: 'OPEN_FOR_BUSINESS_UNSPECIFIED',
        },
        {
          label: this.$t('locationsGmb.id.general.section.openStatus.status.OPEN'),
          value: 'OPEN',
        },
        {
          label: this.$t('locationsGmb.id.general.section.openStatus.status.CLOSED_PERMANENTLY'),
          value: 'CLOSED_PERMANENTLY',
        },
        {
          label: this.$t('locationsGmb.id.general.section.openStatus.status.CLOSED_TEMPORARILY'),
          value: 'CLOSED_TEMPORARILY',
        },
      ],
    }
  },
  computed: {
    verifiedLocation() {
      if (this.currentLocation.locationState?.isVerified) {
        return true
      }
      return false
    },
    activeLocation() {
      if (this.currentLocation.locationState?.isPublished) {
        return true
      }
      return false
    },
    openInfoStatus() {
      if (this.currentLocation?.openInfo?.status) {
        return this.currentLocation.openInfo.status
      }
      return 'OPEN_FOR_BUSINESS_UNSPECIFIED'
    },
    hasPrimaryCategory() {
      if (
        this.currentLocation?.primaryCategory?.displayName &&
        this.currentLocation.primaryCategory.displayName !== ''
      ) {
        return true
      }
      return false
    },
    hasOpeningHours() {
      if (this.currentLocation?.regularHours?.hours) {
        return true
      }
      return false
    },
    exceptionnalHours() {
      if (this.currentLocation?.specialHours?.specialHourPeriods?.length > 0) {
        return this.currentLocation.specialHours.specialHourPeriods
      }
      return []
    },
    hasDescription() {
      return this.currentLocation?.profile?.description && this.currentLocation.profile.description !== ''
    },
    hasPlaceId() {
      return this.currentLocation?.locationKey?.placeId && this.currentLocation.locationKey.placeId !== ''
    },
    addressLines() {
      let address = ''

      if (this.currentLocation.address.addressLines?.length > 0) {
        address += this.currentLocation.address.addressLines[0]
      }

      if (this.currentLocation.address.addressLines?.length > 1) {
        address += this.currentLocation.address.addressLines[1]
      }

      if (this.currentLocation.address.postalCode) {
        address += `, ${this.currentLocation.address.postalCode}`
      }

      if (this.currentLocation.address.locality) {
        address += ` ${this.currentLocation.address.locality}`
      }
      return address
    },
    primaryCategories() {
      return this.categories
    },
    additionalCategories() {
      return this.categories.filter(category => category.categoryId !== this.currentLocation.primaryCategory.categoryId)
    },
    printableAdmins() {
      return this.currentLocation?.admins?.length > 0 ? this.currentLocation.admins : []
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editList(objKey, type, mode, listKey = null) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    editAddress() {
      this.$emit('editAddress', {
        address: {
          street1:
            this.currentLocation.address.addressLines?.length > 0 ? this.currentLocation.address.addressLines[0] : '',
          street2:
            this.currentLocation.address.addressLines?.length > 1 ? this.currentLocation.address.addressLines[1] : '',
          postalCode: this.currentLocation.address.postalCode,
          city: this.currentLocation.address.locality,
          countryCode: this.currentLocation.address.regionCode,
        },
      })
    },
    editPhone() {
      this.$emit('editPhone', {
        phone: this.currentLocation.phone,
        countryCode: this.currentLocation.countryCode,
      })
    },
    editCoordinates() {
      this.$emit('editCoordinates', {
        coordinates: {
          street1: this.currentLocation.address.addressLines[0] || '',
          street2: this.currentLocation.address.addressLines[1] || '',
          postalCode: this.currentLocation.address.postalCode,
          city: this.currentLocation.address.locality,
          countryCode: this.currentLocation.address.regionCode,
          latitude: this.currentLocation.latlng.latitude,
          longitude: this.currentLocation.latlng.longitude,
        },
      })
    },
    editOpeningHours(hourSet) {
      this.$emit('editOpeningHours', {
        hours: { ...hourSet, id: this.currentLocation.locationName } || {
          locationId: this.currentLocation.locationName,
        },
      })
    },
    editWebsite() {
      const url = this.currentLocationUrls.find(url => url.source === 'Website')

      this.$emit('editWebsite', {
        url: url || { source: 'Website', sourceUrl: '', locationId: this.currentLocation.id },
      })
    },
    editExceptionalHours(exceptionalHour) {
      this.$emit('editExceptionalHours', {
        exceptionalHour: exceptionalHour,
      })
    },
    editSubDivisions() {
      this.$emit('editSubDivisions', {
        subDivisions: this.subDivisions,
        locationDivisions: {
          subDivision1: this.currentLocation.subDivision1,
          subDivision2: this.currentLocation.subDivision2,
          subDivision3: this.currentLocation.subDivision3,
        },
      })
    },
    editExternal(value, key) {
      this.$emit('editExternal', {
        module: {
          key,
          value,
        },
        locationModules: this.currentLocation.external,
        externalModules: this.externalModules,
      })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    mapCountry(countryCode) {
      const country = this.countries.find(country => country.countryCode === countryCode)
      return country ? country.name : countryCode
    },
    mapSubDivision(division, level) {
      let subDivision = null
      if (level === 'level1') {
        subDivision = this.subDivisions[level]?.find(sub => sub.code === division)
      } else if (level === 'level2') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision1
        )
      } else if (level === 'level3') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision2
        )
      }
      return subDivision ? subDivision.name : this.$t('errors.subDivisions')
    },
    sortExceptionalOpeningHour(period) {
      this.$emit('sortExceptionalOpeningHour', period.id)
    },
    updateOpenStatus(status) {
      if (status !== this.currentLocation.openInfo.status) {
        this.$emit('updateOpenStatus', status)
      }
    },
    openingDate(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-gmb-general {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-gmb-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-general__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-general__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-general__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-general__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__state {
      margin-top: 2px;

      .location-gmb-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-general__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-general__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-general__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-general__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__button {
      margin-left: $gutter-mobile;
    }

    &__popover {
      min-width: 280px;

      @media (min-width: $screen-sm) {
        min-width: 340px;
      }

      &__choice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $gutter-mobile;
        height: 48px;

        @media (min-width: $screen-sm) {
          padding: 0 $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: $radius-default;
          border-top-right-radius: $radius-default;
        }

        &:last-child {
          border-bottom-left-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
        }

        &__section {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: $gutter-mobile;
          }

          &__loader {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__coordinates {
    &__section {
      display: flex;
      justify-content: space-between;

      &__label {
        font-weight: 500;
      }
    }
  }

  &__hours {
    &__slot {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-mobile / 2;

      &:last-child {
        margin-bottom: 0;
      }

      &__day {
        font-weight: 500;
      }

      &__time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__dropdown {
    max-width: 160px;
  }

  &__table {
    display: block;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
