<template>
  <modal
    :title="title"
    name="modal-hours"
    :has-apply="true"
    :has-delete="canBeDeleted"
    :is-updating="isUpdating"
    :width="900"
    :delete-label="$t('modal.hours.delete.label')"
    :delete-tooltip="$t('modal.hours.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="modal-hours__input"
        v-model.trim="modelData.label"
        :label="$t('modal.hours.label.name')"
        id="modal-hour-name"
        v-if="parameters.type !== 'gmb'"
      />
      <div class="modal-hours__visibility" v-if="parameters.type !== 'gmb'">
        <div class="modal-hours__visibility__block">
          <ui-date-picker
            class="modal-hours__visibility__block__input"
            v-model="dateFrom"
            :locale="$i18n.locale"
            :max-date="dateTo"
            id="modal-media-from"
            :label="$t('modal.hours.label.dateFrom')"
          />
          <ui-button
            class="modal-hours__visibility__block__remove"
            v-if="dateFrom"
            button="secondary"
            icon="close"
            :icon-only="true"
            :label="$t('modal.hours.button.removeDateFrom')"
            v-tooltip="{
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.removeDateFrom'),
              offset: 3,
            }"
            @click="clearDate('dateFrom')"
          />
        </div>
        <div class="modal-hours__visibility__block">
          <ui-date-picker
            class="modal-hours__visibility__block__input"
            v-model="dateTo"
            :locale="$i18n.locale"
            :min-date="dateFrom"
            id="modal-media-to"
            :label="$t('modal.hours.label.dateTo')"
          />
          <ui-button
            class="modal-hours__visibility__block__remove"
            v-if="dateTo"
            button="secondary"
            icon="close"
            :icon-only="true"
            :label="$t('modal.hours.button.removeDateTo')"
            v-tooltip="{
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.removeDateTo'),
              offset: 3,
            }"
            @click="clearDate('dateTo')"
          />
        </div>
      </div>
      <div class="modal-hours__grid">
        <div class="modal-hours__grid__wrapper" v-for="(day, idx) in daysArray" :key="`day-${day.key}-${idx}`">
          <div class="modal-hours__grid__wrapper__block">
            <div class="modal-hours__grid__wrapper__block__day-mobile">
              {{ $t(`common.days.${day.key}`).slice(0, 3) }}.
            </div>
            <div class="modal-hours__grid__wrapper__block__day">
              {{ $t(`common.days.${day.key}`) }}
            </div>
            <ui-switch
              class="modal-hours__grid__wrapper__block__status"
              :id="`switch-${day.key}`"
              v-model="day.open"
              @input="toggleSlots(day)"
            >
              <template v-if="day.open">
                {{ $t('common.label.open') }}
              </template>
              <template v-else>
                {{ $t('common.label.closed') }}
              </template>
            </ui-switch>
            <ui-checkbox
              class="modal-hours__grid__wrapper__block__h24"
              :id="`checkbox-h24-${day.key}`"
              v-model="day.h24"
              v-if="day.open"
              @input="toggleSlots(day)"
            >
              {{ $t('common.label.h24') }}
            </ui-checkbox>
            <div class="modal-hours__grid__wrapper__block__duplicate">
              <v-popover
                placement="bottom-end"
                @apply-hide="stopDuplicating"
                :autoHide="true"
                container=".modal__wrapper__box"
              >
                <ui-button
                  :label="$t('modal.hours.button.duplicate')"
                  button="secondary"
                  icon="copy"
                  variant="gtr"
                  @click="duplicate(day)"
                  :mobile-toggle="true"
                />
                <template slot="popover">
                  <div class="modal-hours__popover">
                    <ui-checkbox
                      class="modal-hours__popover__day"
                      :class="{
                        'modal-hours__popover__day--not-disabled': duplicatingDay && day.key !== duplicatingDay.key,
                      }"
                      v-for="(day, idx) in daysArray"
                      :key="`checkbox-day-mobile-${idx}`"
                      :id="`checkbox-day-mobile-${day.key}`"
                      v-model="duplicateArray"
                      :value="day.key"
                      :disabled="duplicatingDay && day.key === duplicatingDay.key"
                    >
                      {{ $t(`common.days.${day.key}`) }}
                    </ui-checkbox>
                    <ui-button
                      class="modal-hours__popover__cta"
                      :label="$t('modal.hours.button.endDuplicating')"
                      button="secondary"
                      variant="success"
                      @click="endDuplicating"
                      v-close-popover
                    />
                  </div>
                </template>
              </v-popover>
            </div>
          </div>
          <div class="modal-hours__grid__wrapper__slots" v-if="day.open && !day.h24">
            <div
              class="modal-hours__grid__wrapper__slots__line"
              v-for="(slot, idxSlot) in day.slots"
              :key="`slot-${day.key}-${idxSlot}`"
            >
              <ui-dropdown
                class="modal-hours__grid__wrapper__slots__line__input"
                :id="`dropdown-opening-${day.key}-${idx}-${idxSlot}`"
                :key="`dropdown-opening-${day.key}-${idx}-${idxSlot}`"
                v-model="slot.opening"
                :options="hoursOptions"
                :show-caret="false"
                :placeholder="$t('common.label.opening')"
                :error="$v.daysArray.$each[idx].slots.$each[idxSlot].opening.$error"
                :no-results-label="$t('modal.hours.dropdown.noResult')"
              >
                <template
                  v-slot:helper
                  v-if="
                    $v.daysArray.$each[idx].slots.$each[idxSlot].opening.$error &&
                    !$v.daysArray.$each[idx].slots.$each[idxSlot].opening.required
                  "
                >
                  {{ $t('errors.required') }}
                </template>
              </ui-dropdown>
              <ui-dropdown
                class="modal-hours__grid__wrapper__slots__line__input"
                :id="`dropdown-closing-${day.key}-${idx}-${idxSlot}`"
                :key="`dropdown-closing-${day.key}-${idx}-${idxSlot}`"
                v-model="slot.closing"
                :options="hoursOptions"
                :show-caret="false"
                :placeholder="$t('common.label.closing')"
                :error="$v.daysArray.$each[idx].slots.$each[idxSlot].closing.$error"
                :no-results-label="$t('modal.hours.dropdown.noResult')"
              >
                <template
                  v-slot:helper
                  v-if="
                    $v.daysArray.$each[idx].slots.$each[idxSlot].closing.$error &&
                    !$v.daysArray.$each[idx].slots.$each[idxSlot].closing.required
                  "
                >
                  {{ $t('errors.required') }}
                </template>
              </ui-dropdown>
              <ui-button
                class="modal-hours__grid__wrapper__slots__line__cta"
                :label="$t('modal.hours.button.addSlot')"
                button="secondary"
                icon="add_hours"
                :iconOnly="true"
                v-if="idxSlot === 0"
                :disabled="
                  !day.slots[day.slots.length - 1].opening ||
                  !day.slots[day.slots.length - 1].closing ||
                  day.slots.length === 2
                "
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover',
                  content: $t('modal.hours.button.addSlot'),
                  offset: 3,
                }"
                @click="addSlot(day)"
              />
              <ui-button
                class="modal-hours__grid__wrapper__slots__line__cta"
                :label="$t('modal.hours.button.removeSlot')"
                button="secondary"
                icon="close"
                :iconOnly="true"
                v-if="idxSlot > 0"
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover',
                  content: $t('modal.hours.button.removeSlot'),
                  offset: 3,
                }"
                @click="deleteSlot(day, idxSlot)"
              />
            </div>
          </div>
          <div class="modal-hours__grid__wrapper__duplicate">
            <v-popover
              placement="bottom-end"
              @apply-hide="stopDuplicating"
              :autoHide="true"
              container=".modal__wrapper__box"
            >
              <ui-button
                :label="$t('modal.hours.button.duplicate')"
                button="secondary"
                icon="copy"
                variant="gtr"
                @click="duplicate(day)"
              />
              <template slot="popover">
                <div class="modal-hours__popover">
                  <ui-checkbox
                    class="modal-hours__popover__day"
                    :class="{
                      'modal-hours__popover__day--not-disabled': duplicatingDay && day.key !== duplicatingDay.key,
                    }"
                    v-for="(day, idx) in daysArray"
                    :key="`checkbox-day-${idx}`"
                    :id="`checkbox-day-${day.key}`"
                    v-model="duplicateArray"
                    :value="day.key"
                    :disabled="duplicatingDay && day.key === duplicatingDay.key"
                  >
                    {{ $t(`common.days.${day.key}`) }}
                  </ui-checkbox>
                  <ui-button
                    class="modal-hours__popover__cta"
                    :label="$t('modal.hours.button.endDuplicating')"
                    button="secondary"
                    variant="success"
                    @click="endDuplicating"
                    v-close-popover
                  />
                </div>
              </template>
            </v-popover>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import UiInput from '@/components/UI/Input.vue'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import { required } from 'vuelidate/lib/validators'
import { timeArray } from '@/utils/hours.util'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'ModalHours',
  components: {
    UiSwitch,
    UiCheckbox,
    UiDropdown,
    UiButton,
    UiInput,
    UiDatePicker,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
      }),
    },
  },
  data() {
    return {
      modelData: null,
      daysArray: [
        {
          key: 'monday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'tuesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'wednesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'thursday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'friday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'saturday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'sunday',
          open: false,
          h24: false,
          slots: [],
        },
      ],
      duplicatingDay: null,
      duplicateArray: [],
      hoursOptions: timeArray(),
      canBeDeleted: false,
    }
  },
  mounted() {
    this.modelData = Object.assign({}, this.data, {
      ...this.data,
      dateFrom: this.data?.dateFrom || '',
      dateTo: this.data?.dateTo || '',
    })
    if (this.modelData && this.modelData.id) {
      this.canBeDeleted = true
      this.generateDays()
    }
  },
  computed: {
    dateFrom: {
      get: function () {
        if (this.modelData.dateFrom) {
          return new Date(this.modelData.dateFrom)
        }
        return ''
      },
      set(date) {
        this.modelData.dateFrom = formatedDate(date, 'YYYY-MM-DD')
      },
    },
    dateTo: {
      get: function () {
        if (this.modelData.dateTo) {
          return new Date(this.modelData.dateTo)
        }
        return ''
      },
      set(date) {
        this.modelData.dateTo = formatedDate(date, 'YYYY-MM-DD')
      },
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.convertData()
        if (this.modelData.id) {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'update',
          })
        } else {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'create',
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('save', {
        objKey: this.modelData,
        status: 'delete',
      })
    },
    closed() {
      this.$emit('closed')
    },
    addSlot(data) {
      data.slots.push({
        opening: '',
        closing: '',
      })
    },
    deleteSlot(data, index) {
      data.slots.splice(index, 1)
    },
    duplicate(data) {
      this.duplicatingDay = data
    },
    endDuplicating() {
      this.daysArray = this.daysArray.map(day => {
        if (this.duplicateArray.includes(day.key)) {
          return {
            key: day.key,
            open: this.duplicatingDay.open,
            h24: this.duplicatingDay.h24,
            slots: this.duplicatingDay.slots.map(slot => this.cloneSlot(slot)),
          }
        } else {
          return day
        }
      })
      this.duplicatingDay = null
    },
    stopDuplicating() {
      this.duplicateArray = []
    },
    cloneSlot(slot) {
      return Object.assign(
        {},
        {
          opening: slot.opening,
          closing: slot.closing,
        }
      )
    },
    toggleSlots(item) {
      this.daysArray = this.daysArray.map(day => {
        if (day.key === item.key) {
          return {
            ...day,
            slots:
              day.open && !day.h24
                ? [
                    {
                      opening: '',
                      closing: '',
                    },
                  ]
                : [],
          }
        } else {
          return day
        }
      })
    },
    generateDays() {
      const openingHoursArray = []
      const openingHoursObject = Object.entries(this.modelData.hours)

      for (const [day, time] of openingHoursObject) {
        let slots = time.map(t => ({
          opening: t.split('-')[0],
          closing: t.split('-')[1],
        }))
        const h24 =
          slots.length === 1 &&
          (slots[0].opening === '0:00' || slots[0].opening === '00:00') &&
          (slots[0].closing === '0:00' || slots[0].closing === '00:00')
        if (h24) {
          slots = []
        }
        openingHoursArray.push({
          key: day,
          open: slots.length > 0 || h24,
          h24,
          slots,
        })
      }
      this.daysArray = openingHoursArray
    },
    convertData() {
      const hours = {}
      this.daysArray.map(day => {
        if (!day.open) {
          hours[day.key] = []
        } else if (day.h24) {
          hours[day.key] = ['0:00-0:00']
        } else {
          hours[day.key] = day.slots.map(slot => `${slot.opening}-${slot.closing}`)
        }
      })
      this.modelData.hours = hours
    },
    clearDate(date) {
      this.modelData[date] = ''
    },
  },
  validations() {
    return {
      daysArray: {
        $each: {
          slots: {
            $each: {
              opening: {
                required,
              },
              closing: {
                required,
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-hours {
  &__popover {
    overflow: hidden;

    &__day {
      width: 100%;
      font-size: $font-size-default;
    }
  }

  &__grid {
    margin: (-$gutter-mobile) (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-mobile) (-$gutter-tablet / 2);
    }

    &__wrapper {
      position: relative;
      padding: $gutter-mobile $gutter-mobile / 2;

      @media (min-width: $screen-sm) {
        padding: $gutter-mobile $gutter-tablet / 2;
      }

      @media (min-width: $screen-md) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &__block {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: $gutter-mobile;
        align-items: center;

        @media (min-width: $screen-md) {
          grid-template-columns: repeat(3, 1fr);
          padding-top: 13px;
          width: 40%;
        }

        &__day-mobile {
          @media (min-width: $screen-sm) {
            display: none;
          }
        }

        &__day {
          display: none;

          @media (min-width: $screen-sm) {
            display: block;
          }
        }

        &__day,
        &__day-mobile {
          grid-column: 1;
          font-weight: 600;
        }

        &__status {
          grid-column: 2;

          @media (min-width: $screen-sm) {
            justify-self: center;
          }
        }

        &__h24 {
          grid-column: 3;

          @media (min-width: $screen-sm) {
            justify-self: center;
          }
        }

        &__duplicate {
          display: block;
          position: relative;
          grid-column: 4;
          justify-self: flex-end;

          @media (min-width: $screen-md) {
            display: none;
          }
        }
      }

      &__slots {
        @media (min-width: $screen-md) {
          width: 40%;
        }

        &__line {
          display: flex;
          align-items: flex-start;
          margin-top: $gutter-mobile / 2;

          @media (min-width: $screen-sm) {
            margin-top: $gutter-tablet / 2;
          }

          &:first-child {
            @media (min-width: $screen-md) {
              margin-top: 0;
            }
          }

          &__input {
            margin-right: $gutter-mobile / 2;
            width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-mobile} / 2));

            @media (min-width: $screen-sm) {
              margin-right: $gutter-tablet / 2;
              width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-tablet} / 2));
            }
          }
        }
      }

      &__duplicate {
        display: none;

        @media (min-width: $screen-md) {
          display: block;
          position: relative;
          align-self: center;
          justify-self: flex-end;
          margin-top: $gutter-tablet / 2;
          margin-top: 0;
        }

        &:hover {
          cursor: pointer;
        }

        &__icon {
          margin-right: 12px;
          color: map-get($generic-color-variants, 'gtr');
          font-size: 20px;
        }

        &__label {
          color: map-get($generic-color-variants, 'gtr');
          font-weight: 500;
        }
      }
    }
  }

  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }

  &__visibility {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-tablet;
    }

    &__block {
      position: relative;
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-bottom: 0;
        width: calc(50% - #{$gutter-tablet} / 2);
      }

      &__remove {
        position: absolute;
        right: 1px;
        bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.modal-hours {
  &__popover {
    max-width: 220px;
    overflow: hidden;

    &__cta {
      border-radius: 0;
      width: 100%;
      font-size: $font-size-default;
    }

    &__day {
      width: 100%;
      font-size: $font-size-default;

      &--not-disabled {
        &:hover {
          background-color: var(--bg-color-hover);
        }
      }

      .ui-checkbox {
        &__label {
          padding: 0 $gutter-mobile;
          min-height: 48px;
        }
      }
    }
  }
}
</style>
