<template>
  <transition name="fade">
    <div class="ui-table-loader" v-if="isLoading">
      <div class="ui-table-loader__overlay"></div>
      <div class="ui-table-loader__box">
        <div class="ui-table-loader__box__container">
          <div class="ui-table-loader__box__container__title">
            {{ $t('ui.table.loader') }}
          </div>
          <ui-loader class="ui-table-loader__box__container__loader" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UiLoader from '@/components/UI/Loader.vue'

export default {
  name: 'UiTableLoader',
  components: {
    UiLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-table-loader {
  @include modal-wrapper($justify: center, $zIndex: 100, $align: center);

  position: absolute;
  padding: $gutter-tablet;

  &__overlay {
    @include overlay;

    position: absolute;
  }

  &__box {
    @include modal-box(101);

    border-radius: $radius-default;
    height: initial;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $gutter-tablet;

      &__title {
        @include title;

        text-align: center;
      }

      &__loader {
        margin-top: $gutter-tablet;
      }
    }
  }
}
</style>
