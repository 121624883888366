<template>
  <modal name="modal-gmb" :title="title" :has-apply="true" :is-updating="isUpdating" @save="save" @closed="closed">
    <template v-slot:container>
      <div class="modal-gmb__title">
        {{ parameters.options[0].label }}
      </div>
      <div class="modal-gmb__block" v-for="(attr, idx) in parameters.options" :key="`attr-${idx}`">
        <ui-checkbox
          class="modal-gmb__block__checkbox"
          :class="{ 'modal-gmb__block__checkbox--offset': attr.valueType !== attributesValuesTypes.bool }"
          :id="`checkbox-${idx}`"
          :input-value="getStatus(attr)"
          @input="setAttribute($event, attr)"
        />
        <ui-switch
          class="modal-gmb__block__input"
          :id="`bool-attr-${idx}`"
          :reverse="true"
          :input-value="getValue(attr)"
          v-if="attr.valueType === attributesValuesTypes.bool"
          :disabled="!getStatus(attr)"
          @input="setValue($event, attr)"
        >
          {{ attr.displayName }}
        </ui-switch>
        <ui-dropdown
          class="modal-gmb__block__input"
          :no-absolute="true"
          :value="getValue(attr)"
          :options="getOptions(attr)"
          :placeholder="attr.displayName"
          label="label"
          track-by="attributeId"
          :dropdown-label="attr.displayName"
          :show-label="true"
          v-if="attr.valueType === attributesValuesTypes.enum"
          :disabled="!getStatus(attr)"
          @input="setValue($event, attr)"
        />
        <span
          v-if="attr.valueType === attributesValuesTypes.repeatedEnum && !getStatus(attr)"
          class="modal-gmb__block__disabledEnumLabel"
        >
          {{ attr.displayName }}
        </span>
        <div
          v-if="attr.valueType === attributesValuesTypes.repeatedEnum && getStatus(attr)"
          class="modal-gmb__block__enum"
        >
          <span class="modal-gmb__block__enum__label">{{ attr.displayName }}</span>
          <div>
            <ui-button
              v-for="option in getRepeatedEnumFormattedOptions(attr)"
              v-bind:key="option.label"
              class="modal-gmb__block__enum__button"
              button="primary"
              :icon="option.state"
              :label="option.label"
              @click="onRepeatedEnumChangeButtonState(attr, option)"
            />
          </div>
        </div>
        <ui-input
          class="modal-gmb__block__input"
          :id="`url-attr-${idx}`"
          :input-value="getValue(attr)"
          :label="attr.displayName"
          type="url"
          v-if="attr.valueType === attributesValuesTypes.url"
          :disabled="!getStatus(attr)"
          @input="setValue($event, attr)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'ModalGmb',
  components: {
    UiCheckbox,
    UiSwitch,
    UiDropdown,
    UiInput,
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: [String, Object, Array, Number],
      required: true,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        options: [],
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      attributesToRemove: [],
      repeatedEnumButtonsStatus: {
        set: 'success',
        unset: 'error',
        not: 'blocked',
      },
      attributesValuesTypes: {
        bool: 'BOOL',
        enum: 'ENUM',
        repeatedEnum: 'REPEATED_ENUM',
        url: 'URL',
      },
    }
  },
  mounted() {
    this.modelData = JSON.parse(JSON.stringify(this.data))
  },
  methods: {
    removeNotWantedKeys(repeatedEnumValues) {
      return repeatedEnumValues.map(({ attributeId }) => ({ attributeId }))
    },
    getFormattedObjKeyData() {
      const modelDataCpy = JSON.parse(JSON.stringify(this.modelData))
      return modelDataCpy.reduce((objKeyData, attribute) => {
        if (attribute.valueType === this.attributesValuesTypes.repeatedEnum) {
          if (
            attribute.repeatedEnumValue.unsetValues.length === 0 &&
            attribute.repeatedEnumValue.setValues.length === 0
          ) {
            this.attributesToRemove.push(attribute.attributeId)
            return objKeyData
          }
          attribute.repeatedEnumValue.unsetValues = this.removeNotWantedKeys(attribute.repeatedEnumValue.unsetValues)
          attribute.repeatedEnumValue.setValues = this.removeNotWantedKeys(attribute.repeatedEnumValue.setValues)
        }
        return [...objKeyData, attribute]
      }, [])
    },
    save() {
      this.$emit('save', {
        objKey: this.getFormattedObjKeyData(),
        attributesToRemove: this.attributesToRemove,
      })
    },
    closed() {
      this.$emit('closed')
    },
    getStatus(attr) {
      return this.modelData.findIndex(data => data.attributeId === attr.attributeId) > -1
    },
    getValue(attr) {
      const attribute = this.modelData.find(data => data.attributeId === attr.attributeId)
      if (attribute) {
        switch (attribute.valueType) {
          case this.attributesValuesTypes.bool:
            return attribute.values[0]
          case this.attributesValuesTypes.enum:
            return attribute.values[0]
          case this.attributesValuesTypes.url:
            return attribute.urlValues[0].url
        }
      }
      return attr.valueType === this.attributesValuesTypes.bool ? attr.valueMetadata[0].value : attr.valueMetadata[0]
    },
    getOptions(attr) {
      return attr.valueMetadata
    },
    findValueIndex(attribute, key, optionId) {
      return (attribute?.repeatedEnumValue[key] || []).findIndex(({ attributeId }) => attributeId === optionId) ?? -1
    },
    getButtonState(indexSetOption, indexUnsetOption) {
      if (indexSetOption === -1 && indexUnsetOption === -1) {
        return this.repeatedEnumButtonsStatus.not
      } else if (indexSetOption > -1) {
        return this.repeatedEnumButtonsStatus.set
      }
      return this.repeatedEnumButtonsStatus.unset
    },
    getRepeatedEnumFormattedOptions(attr) {
      return this.getOptions(attr).map(item => {
        const locationAttribute = this.modelData.find(({ attributeId }) => attributeId === attr.attributeId)
        const indexAlreadySetOption = this.findValueIndex(locationAttribute, 'setValues', item.attributeId)
        const indexAlreadyUnsetOption = this.findValueIndex(locationAttribute, 'unsetValues', item.attributeId)
        const state = this.getButtonState(indexAlreadySetOption, indexAlreadyUnsetOption)
        return { ...item, state }
      })
    },
    onRepeatedEnumChangeButtonState(attr, selectedOption) {
      delete selectedOption.state
      this.modelData = this.modelData.map(attribute => {
        if (attribute.attributeId === attr.attributeId) {
          const indexAlreadySetOption = this.findValueIndex(attribute, 'setValues', selectedOption.attributeId)
          const indexAlreadyUnsetOption = this.findValueIndex(attribute, 'unsetValues', selectedOption.attributeId)
          if (indexAlreadySetOption === -1 && indexAlreadyUnsetOption === -1) {
            return {
              ...attribute,
              repeatedEnumValue: {
                setValues: [...attribute.repeatedEnumValue.setValues, selectedOption],
                unsetValues: attribute.repeatedEnumValue.unsetValues,
              },
            }
          } else if (indexAlreadySetOption > -1) {
            return {
              ...attribute,
              repeatedEnumValue: {
                unsetValues: [
                  ...attribute.repeatedEnumValue.unsetValues,
                  attribute.repeatedEnumValue.setValues[indexAlreadySetOption],
                ],
                setValues: attribute.repeatedEnumValue.setValues.filter(
                  value => value.attributeId !== selectedOption.attributeId
                ),
              },
            }
          } else {
            return {
              ...attribute,
              repeatedEnumValue: {
                setValues: attribute.repeatedEnumValue.setValues,
                unsetValues: attribute.repeatedEnumValue.unsetValues.filter(
                  value => value.attributeId !== selectedOption.attributeId
                ),
              },
            }
          }
        }
        return attribute
      })
    },
    setAttribute(e, attr) {
      let values = null
      let urlValues = null
      let repeatedEnumValue = null

      switch (attr.valueType) {
        case this.attributesValuesTypes.bool:
          values = [attr.valueMetadata[0].value]
          break
        case this.attributesValuesTypes.enum:
          values = [attr.valueMetadata[0]]
          break
        case this.attributesValuesTypes.repeatedEnum:
          repeatedEnumValue = { setValues: [], unsetValues: [] }
          break
        case this.attributesValuesTypes.url:
          urlValues = [{ url: '' }]
          break
      }
      if (e) {
        this.modelData.push({
          ...attr,
          values,
          urlValues,
          repeatedEnumValue,
        })
        this.attributesToRemove = this.attributesToRemove.filter(({ attributeId }) => attributeId !== attr.attributeId)
      } else {
        this.modelData = this.modelData.filter(({ attributeId }) => attributeId !== attr.attributeId)
        this.attributesToRemove.push(attr.attributeId)
      }
    },
    setValue(e, attr) {
      let values = null
      let urlValues = null
      let repeatedEnumValue = null

      switch (attr.valueType) {
        case this.attributesValuesTypes.bool:
          values = [e]
          break
        case this.attributesValuesTypes.enum:
          values = [e]
          break
        case this.attributesValuesTypes.url:
          urlValues = [{ url: e }]
          break
      }

      this.modelData = this.modelData.map(data => {
        if (data.attributeId === attr.attributeId) {
          return {
            ...data,
            values,
            urlValues,
            repeatedEnumValue,
          }
        } else {
          return data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-gmb {
  &__title {
    margin-bottom: 16px;
    font-weight: 600;
  }

  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__checkbox {
      &--offset {
        margin-top: 20px;
      }
    }

    &__input {
      flex: 1;
    }

    &__disabledEnumLabel {
      margin-top: auto;
      height: auto;
      color: #8d8e99;
    }

    &__enum {
      flex: 1;
      border-radius: $radius-input;
      background: $input-color-bg;
      padding: 8px 16px;

      &__label {
        color: $checkbox-color-text;
      }

      &__button {
        margin: 4px auto;
        width: auto;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        color: $generic-color-gtr;
      }
    }
  }
}
</style>
