<template>
  <div
    class="ui-checkbox"
    :class="{
      'ui-checkbox--disabled': disabled,
    }"
  >
    <input
      type="checkbox"
      :id="id"
      :disabled="disabled"
      class="ui-checkbox__input"
      :checked="isChecked"
      @change="onChange"
    />
    <label :for="id" class="ui-checkbox__label" :class="[`ui-checkbox__label--${align}`]">
      <span class="ui-checkbox__label__text" v-if="reverse">
        <slot></slot>
      </span>
      <span class="ui-checkbox__label__icon" :class="{ 'ui-checkbox__label__icon--reverse': reverse }">
        <svg viewBox="0 0 24 24" class="ui-checkbox__label__icon__svg">
          <path class="ui-checkbox__label__icon__svg__path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
        </svg>
      </span>
      <span class="ui-checkbox__label__text" v-if="!reverse">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiCheckbox',
  model: {
    prop: 'inputValue',
  },
  props: {
    inputValue: {
      type: [Array, Boolean, Number, String],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: [String, Number, Boolean],
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.inputValue)
    },
    isChecked() {
      if (this.isArray) {
        return this.inputValue.includes(this.value)
      }
      return !!this.inputValue
    },
  },
  methods: {
    onChange(e) {
      const checked = e.target.checked
      if (this.isArray) {
        if (checked) {
          /**
           * Emitted when the checkbox is checked/unchecked.
           * @event input
           * @type {Event}
           */
          this.$emit('input', this.inputValue.concat([this.value]))
        } else {
          this.$emit(
            'input',
            this.inputValue.filter(v => v !== this.value)
          )
        }
      } else {
        this.$emit('input', checked)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-checkbox {
  @include input;

  display: inline;

  &__label {
    display: flex;
    position: relative;
    flex: 1;
    align-items: center;
    cursor: pointer;

    &--space-between {
      justify-content: space-between;
    }

    &--right {
      text-align: right;
    }

    .ui-checkbox__input:disabled + & {
      cursor: default;
    }

    &__icon {
      position: relative;
      transition: all $transition-duration-default $transition-effect-default;
      margin-right: 12px;
      border: 2px solid $checkbox-color-border;
      border-radius: $radius-checkbox;
      background-color: $checkbox-color-bg;
      width: $checkbox-size;
      min-width: $checkbox-size;
      height: $checkbox-size;
      min-height: $checkbox-size;

      &--reverse {
        margin-right: 0;
        margin-left: 12px;
      }

      .ui-checkbox__input:checked + label > & {
        border-color: $checkbox-color-border-hover;
        background-color: $checkbox-color-bg-hover;
      }

      .ui-checkbox__input:checked:disabled + label > & {
        border-color: $checkbox-color-border;
        background-color: $checkbox-color-border;
      }

      .ui-checkbox__input:hover:not(:disabled) + label > &,
      .ui-checkbox__input:focus:not(:disabled) + label > & {
        border-color: $checkbox-color-border-hover;
      }

      &__svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1px;
        width: 100%;

        &__path {
          stroke: $checkbox-color-svg;
          stroke-width: 3.12px;
          stroke-dashoffset: 29.7833385;
          stroke-dasharray: 29.7833385;
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: all $transition-duration-medium $transition-effect-default;

          .ui-checkbox__input:checked + label & {
            stroke-dashoffset: 0;
          }
        }
      }
    }

    &__text {
      transition: color $transition-duration-default $transition-effect-default;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $checkbox-color-text;

      .ui-checkbox__input:hover:not(:disabled) + label > &,
      .ui-checkbox__input:focus:not(:disabled) + label > & {
        color: $checkbox-color-text-hover;
      }
    }
  }

  &__input {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
}
</style>
