<template>
  <screen-grid class="location-gmb-home">
    <screen-card
      :title="$t(`locationsGmb.id.home.section.status.title`)"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-for="(value, key) in ObjectOfLocationState"
          :key="key"
          :title="$t(`locationsGmb.id.home.section.status.locationState.label.${key}`)"
          ratio="1-3"
          :has-data="true"
          :class="{
            'location-gmb-home__status--active': value,
            'location-gmb-home__status--inactive': !value,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div class="location-gmb-home__status__icon backoffice-icons" v-else>check</div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-gmb-home__status__state" v-else>
              <template v-if="value">
                {{ $t('common.button.yes') }}
              </template>
              <template v-else>
                {{ $t('common.button.no') }}
              </template>
            </div>
          </template>
        </screen-block>

        <screen-block
          :title="$t('locationsGmb.id.home.section.status.openStatus.label')"
          ratio="1-3"
          :class="`location-gmb-home__status--${openInfoStatus}`"
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-gmb-home__status__icon backoffice-icons">hours</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-gmb-home__status__state" v-else>
              {{ $t(`locationsGmb.id.home.section.status.openStatus.status.${openInfoStatus}`) }}
            </div>
          </template>
          <template v-slot:right>
            <skeleton-icon v-if="isLoading" height="44px" width="44px" margin-left="16px" />
            <v-popover placement="bottom-end" :autoHide="true" popoverInnerClass="popover-inner no-overflow" v-else>
              <ui-button
                class="location-gmb-home__status__button"
                button="primary"
                variant="data"
                icon="edit"
                :icon-only="true"
                :label="$t('common.button.edit')"
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover',
                  content: $t('common.button.edit'),
                  offset: 3,
                }"
              />
              <template slot="popover">
                <div class="location-gmb-home__status__popover">
                  <div
                    class="location-gmb-home__status__popover__choice"
                    v-for="status in openStatuses"
                    :key="status.value"
                    @click="updateOpenStatus(status.value)"
                  >
                    <div class="location-gmb-home__status__popover__choice__section">
                      <span
                        class="location-gmb-home__status__popover__choice__section__icon backoffice-icons"
                        v-if="openInfoStatus === status.value"
                      >
                        check
                      </span>
                      <span class="location-gmb-home__status__popover__choice__section__label">
                        {{ status.label }}
                      </span>
                    </div>
                    <div class="location-gmb-home__status__popover__choice__section">
                      <ui-loader
                        class="location-gmb-home__status__popover__choice__section__loader"
                        v-if="isUpdating && openInfoStatus === status.value"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </v-popover>
          </template>
        </screen-block>

        <screen-block
          v-if="isLocationHasPendingState"
          class="location-gmb-home__pending"
          title=""
          ratio="1-1"
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="10px" width="100%" />
            <div class="location-gmb-home__pending__data">
              {{ $t('locationsGmb.id.home.section.status.pending') }}
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>

    <screen-card
      :title="$t(`locationsGmb.id.home.section.activity.title`)"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          ratio="1-2"
          class="location-gmb-home__activity"
          title=""
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:data>
            <div class="location-gmb-home__activity__data">
              {{ $t('locationsGmb.id.home.section.activity.questions') }}
            </div>
          </template>
        </screen-block>

        <screen-block
          ratio="1-2"
          class="location-gmb-home__activity"
          title=""
          :has-data="true"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:data>
            <div class="location-gmb-home__activity__data">
              {{ $t('locationsGmb.id.home.section.activity.reviews') }}
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>

    <screen-card
      :title="$t('locationsGmb.id.general.section.exceptionalOpeningHours.title')"
      ratio="1-1"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <div class="location-gmb-home__EOH" :class="{ 'location-gmb-home__EOH--smallDevice': isMobile || isTablet }">
          <div class="location-gmb-home__EOH__col" v-for="(column, index) in columns" :key="index + 'col'">
            <screen-block
              v-for="(exceptionalHour, idx) in column"
              :key="`exceptional-hour-${idx}`"
              title=""
              :button="isExceptionalHourFromPHW(exceptionalHour) ? 'add_hours' : ''"
              :has-data="exceptionnalHours.length > 0"
              class="location-gmb-home__EOH__col__hours"
              :class="{ 'location-gmb-home__EOH__col__hours--not-loading': !isLoading }"
              ratio="1-1"
              :isEditable="!isExceptionalHourFromPHW(exceptionalHour)"
              :is-hover="false"
              :is-loading="isLoading"
              @click="editExceptionalHours(exceptionalHour)"
            >
              <template v-slot:data>
                <div
                  class="location-gmb-home__EOH__col__hours__month"
                  v-if="monthIsDifferent(exceptionalHour.dateFrom, column, index, idx)"
                >
                  {{ $t(`locationsGmb.id.home.section.date[${getMonth(exceptionalHour.dateFrom)}]`) }}
                </div>
                <div
                  class="location-gmb-home__EOH__col__hours__suggested"
                  v-if="fromPHWIsDifferent(exceptionalHour.fromPHW, column, index, idx)"
                >
                  {{ $t('locationsGmb.id.home.section.dateSuggested') }}
                </div>
                <div
                  class="location-gmb-home__EOH__col__hours__slot"
                  :class="{
                    'location-gmb-home__EOH__col__hours__slot--active': !isExceptionalHourFromPHW(exceptionalHour),
                  }"
                >
                  <div class="location-gmb-home__EOH__col__hours__slot__day">
                    <template
                      v-if="
                        exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                        (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                      "
                    >
                      {{ $t('common.date.on') }}
                      {{ date(exceptionalHour.dateFrom) }}
                    </template>
                    <template v-else>
                      {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                      {{ date(exceptionalHour.dateTo) }}
                    </template>
                    <div class="location-gmb-home__EOH__col__hours__slot__day__label" v-if="exceptionalHour.fromPHW">
                      {{ exceptionalHour.event.versions[localeLang.toUpperCase()] || exceptionalHour.event.default }}
                    </div>
                  </div>

                  <div class="location-gmb-home__EOH__col__hours__slot__action">
                    <ui-button
                      v-if="exceptionalHour.fromPHW"
                      class="location-gmb-home__EOH__col__hours__slot__action__button"
                      @click="editExceptionalHours(exceptionalHour)"
                      button="secondary"
                      icon="add"
                      :label="$t('common.button.holiday')"
                    >
                    </ui-button>
                  </div>
                  <div
                    v-if="!isExceptionalHourFromPHW(exceptionalHour)"
                    class="location-gmb-home__EOH__col__hours__slot__time"
                  >
                    <div v-if="!exceptionalHour.openStatus">
                      {{ $t('common.label.closed') }}
                    </div>
                    <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                      {{ $t('common.label.open') }}
                    </div>
                    <template v-else>
                      <div v-if="exceptionalHour.hours1">
                        {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
                      </div>
                      <div v-if="exceptionalHour.hours2">
                        {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </screen-block>
          </div>
        </div>
        <screen-block
          v-if="exceptionnalHours.length === 0"
          :title="$t('locationsGmb.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="date"
          :has-data="exceptionnalHours.length > 0"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locationsGmb.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          @click="editExceptionalHours()"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { isMobile, isTablet } from '@/utils/navigator.util'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiLoader from '@/components/UI/Loader.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'LocationHome',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ScreenAction,
    SkeletonIcon,
    SkeletonLine,
    UiButton,
    UiLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    localeLang: {
      type: String,
      required: false,
      default: 'default',
    },
    exceptionnalHoursApi: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      openStatuses: [
        {
          label: this.$t('locationsGmb.id.home.section.status.openStatus.status.OPEN_FOR_BUSINESS_UNSPECIFIED'),
          value: 'OPEN_FOR_BUSINESS_UNSPECIFIED',
        },
        {
          label: this.$t('locationsGmb.id.home.section.status.openStatus.status.OPEN'),
          value: 'OPEN',
        },
        {
          label: this.$t('locationsGmb.id.home.section.status.openStatus.status.CLOSED_PERMANENTLY'),
          value: 'CLOSED_PERMANENTLY',
        },
        {
          label: this.$t('locationsGmb.id.home.section.status.openStatus.status.CLOSED_TEMPORARILY'),
          value: 'CLOSED_TEMPORARILY',
        },
      ],
      isMobile: isMobile(),
      isTablet: isTablet(),
    }
  },
  computed: {
    columns() {
      let columns = []
      let mid = Math.ceil(Object.keys(this.exceptionnalHours).length / 2)

      for (let col = 0; col < 2; col++) {
        columns.push(
          Object.entries(this.exceptionnalHours)
            .slice(col * mid, col * mid + mid)
            .map(entry => entry[1])
        )
      }
      if (this.getMonth(columns[0]?.[columns[0].length - 1]?.dateFrom) === this.getMonth(columns[1]?.[0]?.dateFrom)) {
        columns[0].push(columns[1].splice(0, 1)[0])
      }
      return columns
    },
    ObjectOfLocationState() {
      let locationStateNeeded = ['isVerified', 'isPublished']
      let locationStateActive = {}

      if (this.currentLocation?.locationState) {
        Object.keys(this.currentLocation.locationState).forEach(key => {
          if (locationStateNeeded.includes(key)) {
            locationStateActive[key] = this.currentLocation.locationState[key]
          }
        })
      }
      return locationStateActive
    },
    openInfoStatus() {
      if (this.currentLocation?.openInfo?.status) {
        return this.currentLocation.openInfo.status
      }
      return 'OPEN_FOR_BUSINESS_UNSPECIFIED'
    },
    isLocationHasPendingState() {
      let locationPendingState = ['hasPendingEdits', 'isPendingReview', 'hasPendingVerification']
      return this.currentLocation?.locationState
        ? Object.entries(this.currentLocation.locationState).some(
            obj => locationPendingState.includes(obj[0]) && obj[1] === true
          )
        : false
    },
    exceptionnalHours() {
      if (this.currentLocation?.specialHours?.specialHourPeriods?.length > 0) {
        const exceptionnalHoursOrderByDateAndSource = this.currentLocation.specialHours.specialHourPeriods
          .concat(
            this.exceptionnalHoursApi?.publicHolidays?.filter(publicHoliday => {
              return this.currentLocation.specialHours.specialHourPeriods.every(
                except => except.dateFrom != publicHoliday.dateFrom
              )
            })
          )
          .sort((currExceptionnalHour, oldExceptionnalHour) => {
            if (currExceptionnalHour.dateFrom !== oldExceptionnalHour.dateFrom) {
              return currExceptionnalHour.dateFrom
                .substring(0, currExceptionnalHour.dateFrom.length - 3)
                .localeCompare(oldExceptionnalHour.dateFrom.substring(0, oldExceptionnalHour.dateFrom.length - 3))
            } else if (currExceptionnalHour.fromPHW) {
              return 1
            } else {
              return -1
            }
          })
        return exceptionnalHoursOrderByDateAndSource
      }
      return this.exceptionnalHoursApi?.publicHolidays || {}
    },
  },
  methods: {
    updateOpenStatus(status) {
      if (status !== this.currentLocation.openInfo.status) {
        this.$emit('updateOpenStatus', status)
      }
    },
    editExceptionalHours(exceptionalHour) {
      this.$emit('editExceptionalHours', {
        exceptionalHour: exceptionalHour,
      })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    monthIsDifferent(currExceptionnalHour, column, indexCol, indexItem) {
      if (indexItem === 0 && indexCol === 1) {
        column = this.columns[0]
        indexItem = this.columns[0].length
      }
      return this.getMonth(currExceptionnalHour) != this.getMonth(column[indexItem - 1]?.dateFrom)
    },
    fromPHWIsDifferent(currExceptionnalHour, column, indexCol, indexItem) {
      let currColumn = column
      let currIndexItem = indexItem

      if (currIndexItem === 0 && indexCol === 1) {
        currColumn = this.columns[0]
        currIndexItem = this.columns[0].length
      }
      return currExceptionnalHour ? currExceptionnalHour != currColumn[currIndexItem - 1]?.fromPHW : false
    },
    isExceptionalHourFromPHW(exceptionalHour) {
      return !!exceptionalHour?.fromPHW
    },
    getMonth(date) {
      return new Date(date).getMonth()
    },
  },
}
</script>

<style lang="scss" scoped>
.location-gmb-home {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-gmb-home__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-home__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-home__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-home__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-home__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-home__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__state {
      .location-gmb-home__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-home__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-home__status--OPEN_FOR_BUSINESS_UNSPECIFIED & {
        color: map-get($generic-color-variants, 'inactive');
      }

      .location-gmb-home__status--OPEN & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-gmb-home__status--CLOSED_PERMANENTLY & {
        color: map-get($generic-color-variants, 'error');
      }

      .location-gmb-home__status--CLOSED_TEMPORARILY & {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__button {
      margin-left: $gutter-mobile;
    }

    &__popover {
      min-width: 280px;

      @media (min-width: $screen-sm) {
        min-width: 340px;
      }

      &__choice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $gutter-mobile;
        height: 48px;

        @media (min-width: $screen-sm) {
          padding: 0 $gutter-tablet;
        }

        &:hover {
          background-color: var(--bg-color-hover);
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: $radius-default;
          border-top-right-radius: $radius-default;
        }

        &:last-child {
          border-bottom-left-radius: $radius-default;
          border-bottom-right-radius: $radius-default;
        }

        &__section {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: $gutter-mobile;
          }

          &__loader {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__pending {
    &__data {
      color: map-get($generic-color-variants, 'warning');
    }
  }

  &__activity {
    text-align: center;
  }

  &__EOH {
    display: flex;
    flex-direction: row;
    width: 100%;

    &--smallDevice {
      flex-wrap: wrap;
    }

    &__col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &__hours {
        &--not-loading {
          padding: 0 $gutter-mobile / 2;
        }
        &__month {
          margin: 6px 0;
          color: $black;
          font-weight: 500;
        }

        &__suggested {
          margin: 4px 4px 0 6px;
          color: $grey-french;
          font-weight: 500;
        }

        &__slot {
          display: grid;
          grid-template-columns: 2fr 1fr auto;
          transition: all $transition-duration-very-fast $transition-effect-default;
          border-radius: $radius-default;
          padding: 7px 5px;

          &--active:hover {
            background-color: var(--bg-color-hover);
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__day {
            grid-column: 1;
            align-self: center;
            margin-left: 6px;
            font-weight: 400;
          }

          &__action {
            grid-column: 2;
            text-align: end;
            &__button {
              width: max-content;
              height: 36px;
              color: map-get($generic-color-variants, 'success');
              font-size: $font-size-sm;
            }
          }

          &__time {
            display: flex;
            flex-direction: column;
            grid-column: 3;
            align-items: flex-end;
            align-self: center;
            color: $black;
          }
        }
      }
    }
  }
}
</style>
