<template>
  <modal
    :title="title"
    name="modal-coordinates"
    :has-apply="true"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
    @opened="initMap"
  >
    <template v-slot:container>
      <ui-input
        class="modal-coordinates__input"
        v-model.number.trim="modelData.latitude"
        :label="$t('modal.gps.label.latitude')"
        id="modal-coordinates-latitude"
        :error="$v.modelData.latitude.$error"
        @enter="save"
        @input="refreshMarkerPosition"
      >
        <template v-if="$v.modelData.latitude.$error && !$v.modelData.latitude.required">
          {{ $t('errors.required') }}
        </template>
        <template v-if="$v.modelData.latitude.$error && !$v.modelData.latitude.decimal">
          {{ $t('errors.decimal') }}
        </template>
      </ui-input>
      <ui-input
        class="modal-coordinates__input"
        v-model.number.trim="modelData.longitude"
        :label="$t('modal.gps.label.longitude')"
        id="modal-coordinates-longitude"
        :error="$v.modelData.longitude.$error"
        @enter="save"
        @input="refreshMarkerPosition"
      >
        <template v-if="$v.modelData.longitude.$error && !$v.modelData.longitude.required">
          {{ $t('errors.required') }}
        </template>
        <template v-if="$v.modelData.latitude.$error && !$v.modelData.latitude.decimal">
          {{ $t('errors.decimal') }}
        </template>
      </ui-input>
      <gmap-map class="modal-coordinates__map" :center="{ lat: 0, lng: 0 }" ref="gmap"> </gmap-map>
      <div class="modal-coordinates__actions">
        <ui-button
          class="modal-coordinates__actions__cta"
          button="primary"
          variant="data"
          :label="$t('modal.gps.button.generate')"
          :disabled="isGeocoding"
          @click="generateCoordinates"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import { required, decimal } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ModalCoordinates',
  components: {
    UiInput,
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        street1: '',
        street2: '',
        postalCode: '',
        city: '',
        countryCode: '',
        latitude: 0,
        longitude: 0,
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      marker: null,
      map: null,
      isGeocoding: false,
    }
  },
  mounted() {
    this.modelData = this.data
  },
  methods: {
    ...mapActions({
      geocodeAddress: 'tool/geocodeAddress',
    }),
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', {
          objKey: this.modelData,
        })
        this.$v.$reset()
      }
    },
    closed() {
      this.$emit('closed')
    },
    initMap() {
      this.$refs.gmap.$mapPromise.then(map => {
        this.map = map
        // eslint-disable-next-line no-undef
        this.marker = new google.maps.Marker({
          position: { lat: this.modelData.latitude || 0, lng: this.modelData.longitude || 0 },
          draggable: true,
          map: map,
        })
        this.mapFitBounds(map)

        // eslint-disable-next-line no-undef
        google.maps.event.addListener(this.marker, 'drag', () => {
          const position = this.marker.getPosition()
          this.modelData.latitude = position.lat()
          this.modelData.longitude = position.lng()
        })
      })
    },
    mapFitBounds(map) {
      const bounds = new google.maps.LatLngBounds() // eslint-disable-line no-undef
      bounds.extend({ lat: this.modelData.latitude || 0, lng: this.modelData.longitude || 0 })
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng( // eslint-disable-line no-undef
          bounds.getNorthEast().lat() + 0.005,
          bounds.getNorthEast().lng() + 0.005
        )
        const extendPoint2 = new google.maps.LatLng( // eslint-disable-line no-undef
          bounds.getNorthEast().lat() - 0.005,
          bounds.getNorthEast().lng() - 0.005
        )
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }

      map.fitBounds(bounds)
    },
    refreshMarkerPosition() {
      if (typeof this.modelData.latitude === 'number' && typeof this.modelData.longitude === 'number') {
        this.marker.setPosition(
          // eslint-disable-next-line no-undef
          new google.maps.LatLng(parseFloat(this.modelData.latitude), parseFloat(this.modelData.longitude))
        )
        this.mapFitBounds(this.map)
      }
    },
    generateCoordinates() {
      this.isGeocoding = true
      let fullAddress = this.modelData.street1
      if (this.modelData.postalCode) {
        fullAddress += `,${this.modelData.postalCode}`
      }
      if (this.modelData.city) {
        fullAddress += `,${this.modelData.city}`
      }
      if (this.modelData.countryCode) {
        fullAddress += `,${this.modelData.countryCode}`
      }
      this.geocodeAddress(fullAddress).then(coordinates => {
        this.modelData = {
          ...this.modelData,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        }
        this.isGeocoding = false
        this.refreshMarkerPosition()
      })
    },
  },
  validations() {
    return {
      modelData: {
        latitude: {
          required,
          decimal,
        },
        longitude: {
          required,
          decimal,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-coordinates {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__map {
    width: 100%;
    height: 300px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
    }
  }
}
</style>
