<template>
  <div class="skeleton-input" :style="{ marginTop, marginLeft, marginRight, width }"></div>
</template>

<script>
export default {
  name: 'SkeletonInput',
  props: {
    width: {
      type: String,
      required: false,
      default: '',
    },
    marginTop: {
      type: String,
      required: false,
      default: '0',
    },
    marginLeft: {
      type: String,
      required: false,
      default: '0',
    },
    marginRight: {
      type: String,
      required: false,
      default: '0',
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton-input {
  @include skeleton-loader;

  border-radius: $radius-button;
  width: $button-min-width;
  height: $button-min-height;
}
</style>
